import React from 'react';
import PropTypes from 'prop-types'

const BASE_BUTTON = 'rounded outline-none shadow py-3 px-12 font-normal uppercase tracking-wider text-lg'
const CONTAINED_BUTTON = `${BASE_BUTTON} bg-green-400 border border-green-400 text-white`
const OUTLINED_BUTTON = `${BASE_BUTTON} border border-green-400 text-green-400`


export const Button = ({ onClick, label, outline, size }) => {
    return (
        <button
            onClick={onClick}
            className={outline ? OUTLINED_BUTTON : CONTAINED_BUTTON}
        >
            <span>{label}</span>
        </button>
    )
}

Button.propTypes = {
    primary: PropTypes.bool,
    backgroundColor: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func
}

Button.defaultProps = {
    backgroundColor: null,
    primary: false,
    size: 'medium',
    onClick: undefined
}
