import React from 'react';
import { Button } from 'components/Button/Button'

class Test extends React.Component {
    render() {
        return (
            <div>
                <h1>Test Page</h1>
                <Button size='large' onClick='' label='Hello!' />
            </div>
        )
    }
}

export default Test


