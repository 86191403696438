import React from "react"
import { Router } from "@reach/router"
import Layout from "components/layout"
// import ArtistPage from "components/ArtistPage"
// import Profile from "components/Profile"
// import Public from "components/Public"
import Login from 'components/Login'
// import Login from "../components/Login"
// import SignUp from "components/SignUp"
// import PrivateRoute from "components/PrivateRoute"
import Test from 'components/test'


const App = () => (
    <Layout>
        <Router>
            <Login path="/app/login" />
            <Test path='/app/test' />
        </Router>
    </Layout>
)

export default App

/*
<PrivateRoute path="/app/home" component={Public} />
<PrivateRoute path="/app/profile" component={Profile} />
<PrivateRoute path="/app/artists" component={ArtistPage} />
<Login path="/app/login" />
<SignUp path="/app/signup" />
*/
